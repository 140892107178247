/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';

// components
import { Quote } from '../components/Quote';

export const Pagination = ({
	data,
	user,
	liked,
	authorList,
	setRenderMain,
	setDataList,
	setOpenLogin,
	handleScroll,
}) => {
	// Products per page
	const QUOTES_PER_PAGE = 24;

	// State for managing current page and products on that page
	const [currentPage, setCurrentPage] = useState(1);
	const [currentQuotes, setCurrentQuotes] = useState([]);

	// Calculate total pages based on products length
	const totalPages = Math.ceil(data?.length / QUOTES_PER_PAGE);

	// Next page handler
	const handleNextPage = () => {
		handleScroll();
		if (currentPage < totalPages) setCurrentPage(currentPage + 1);
	};

	// Previous page handler
	const handlePreviousPage = () => {
		handleScroll();
		if (currentPage > 1) setCurrentPage(currentPage - 1);
	};

	// Reset currentPage to 1 whenever products change
	useEffect(() => {
		setCurrentPage(1);
	}, [data]);

	// Update current products based on the current page
	useEffect(() => {
		const startIndex = (currentPage - 1) * QUOTES_PER_PAGE;
		const endIndex = startIndex + QUOTES_PER_PAGE;
		setCurrentQuotes(data?.slice(startIndex, endIndex));
	}, [currentPage, data]);

	return (
		<>
			<>
				{currentQuotes?.map((quote) => (
					<div key={quote._id}>
						<Quote
							user={user}
							quote={quote}
							liked={liked}
							authorList={authorList}
							setRenderMain={setRenderMain}
							setDataList={setDataList}
							setOpenLogin={setOpenLogin}
						/>
					</div>
				))}
			</>
			{totalPages > 1 && (
				<div
					className="pagination-controls"
					style={{ marginTop: '2em', marginBottom: '1em' }}
				>
					<button
						className="btn"
						onClick={handlePreviousPage}
						// disabled={currentPage === 1}
					>
						Previous
					</button>
					<span style={{ margin: '0 1em' }}>
						Page {currentPage} of {totalPages}
					</span>
					<button
						className="btn"
						onClick={handleNextPage}
						// disabled={currentPage === totalPages}
					>
						Next
					</button>
				</div>
			)}
		</>
	);
};
